/* All entries alphabetical, except: body, *, theme at bottom */

#three-container {
    position: absolute;
    width: 100%;
    height: 50%;
    opacity: 0.3;
}

.centered {
    text-align: center;
}

.card-height {
    min-height: 350px;
    margin: 10px;
}

.company-logo-name-container {
    display: inline-block;
    padding-left: 10px;
}

.corner-sharp {
    border-radius: 1px !important;
}

.fix-sidebar {
    transform: none !important;
}

.font-heading {
    font-family: "Josefin Sans", sans-serif;
}

.font-size-small {
    font-size: 1rem;
    line-height: 1.25rem;
}

.font-size-medium {
    font-size: 1.5rem;
    line-height: 2rem;
}

.font-size-large {
    font-size: 2rem;
    line-height: 4rem;
}

.font-size-huge {
    font-size: 3rem;
    line-height: 4rem;
}

.heading-outer {
    position: relative;
    width: 100%;
}

.font-heading {
    font-family: "Josefin Sans", sans-serif;
}

.image-circle-portrait {
    border-radius: 50%;
    object-fit: cover;
    min-height: 250px;
    max-height: 500px;
}

.image-circle-small-skill {
    min-width: 70px !important;
    max-width: 80px !important;
    /* height: 80px !important; */
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid rgba(0, 129, 129, 0.3);
}

.image-circle-small-skill img {
    position: absolute;
    left: -1000%;
    right: -1000%;
    top: -1000%;
    bottom: -1000%;
    margin: auto;
    min-height: 100%;
    min-width: 100%;
}

img {
    height: auto;
    max-width: 100%;
}

.left {
    text-align: left;
}

.text {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 2rem;
    text-align: justify;
}

/* ----------------- THEMES ------------------- */

.theme-default-heading {
    background: linear-gradient(
        90deg,
        rgba(255, 0, 191, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
    );
}

.theme-default:before {
    content: "";
    position: fixed;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: rgb(0, 191, 255);
    background: linear-gradient(
        0deg,
        rgba(0, 191, 255, 0.2) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    background-image: url(/images/light_noise_diagonal_@2X.png) green;
    opacity: 0.4;
}

.theme-default:after {
    content: "";
    position: fixed;
    z-index: -1;
    backface-visibility: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: grayscale(10%) invert(10%);
    background: rgb(0, 191, 255);
    background: linear-gradient(
        0deg,
        rgba(0, 191, 255, 0.2) 0%,
        rgba(255, 255, 255, 1) 100%
    );
}

.theme-autumn-heading {
    background: linear-gradient(
        90deg,
        rgba(60, 97, 106, 1) 0%,
        rgba(255, 255, 255, 0) 100%
    );
}

.theme-autumn:before {
    content: "";
    position: fixed;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: rgb(160, 41, 17);
    background: linear-gradient(
        0deg,
        rgba(160, 41, 17, 1) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    opacity: 0.4;
}

.theme-autumn:after {
    content: "";
    position: fixed;
    z-index: -1;
    backface-visibility: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: grayscale(10%) invert(10%);
    background: rgb(160, 41, 17);
    background: linear-gradient(
        0deg,
        rgba(160, 41, 17, 0.2) 0%,
        rgba(255, 255, 255, 1) 100%
    );
}

.theme-ice-heading {
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
    );
}

.theme-ice:before {
    content: "";
    position: fixed;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: rgb(25, 149, 173);
    background: linear-gradient(
        0deg,
        rgba(25, 149, 173, 0.2) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    opacity: 0.4;
}

.theme-ice:after {
    content: "";
    position: fixed;
    z-index: -1;
    backface-visibility: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: grayscale(10%) invert(10%);
    background: rgb(25, 149, 173);
    background: linear-gradient(
        0deg,
        rgba(25, 149, 173, 0.2) 0%,
        rgba(255, 255, 255, 1) 100%
    );
}

.theme-berries-heading {
    background: linear-gradient(
        90deg,
        rgba(0, 102, 46, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    box-shadow: 0 0 80px 0 rgba(255, 255, 255, 0.4s);
}

.theme-berries:before {
    content: "";
    position: fixed;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-color: rgb(194, 229, 156, 0.2);
    background-image: linear-gradient(
        180deg,
        rgb(194, 229, 156, 0.8) 0%,
        rgb(100, 179, 244, 1) 74%
    );
    opacity: 0.4;
}

.theme-berries:after {
    content: "";
    position: fixed;
    z-index: -1;
    backface-visibility: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Super Secret Evil */

.image-circle-portrait {
    max-width: 100%;
    min-width: 50%;
    height: auto;
    object-fit: contain;
}

/* .image-circle-portrait-container {
  position:  relative;
  object-fit: cover;

} */

/* .image-circle-portrait-alt {

  position: absolute;
  border-radius: 50%;
  flex: auto;

  border: 1px solid teal;

  -webkit-transition: opacity 5s ease-in-out;
  -moz-transition: opacity 5s ease-in-out;
  -o-transition: opacity 5s ease-in-out;
  transition: opacity 5s ease-in-out;
  transition-delay: 2s;
  opacity: 0;
} */

.image-circle-portrait-alt:hover {
    opacity: 0.99;
}
